<template>
  <a-tag color="orange" v-if="status === 'waiting'">
    <span class="text-sm">{{ status }}</span>
  </a-tag>

  <a-tag color="green" v-if="status === 'approved'">
    <span class="text-sm">{{ status }}</span>
  </a-tag>

  <a-tag color="gray" v-if="status === 'rejected'">
    <span class="text-sm">{{ status }}</span>
  </a-tag>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class LeaveStatusBadge extends Vue {
  @Prop({ default: '' })
  status!: string
}
</script>
