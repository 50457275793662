
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { cloneDeep } from 'lodash'
import { ELeaveMorningEvening, ELeaveStatus, ILeave, ILeaveDetail, IUser } from '@/utils/types'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import FireLeave from '@/services/leaves'
import moment from 'moment'
import { calculateTotalLeaveByDetail } from '@/utils/helpers'

@Options({
  components: { SearchUserInput, DeleteOutlined, PlusOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class LeaveFormModal extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  defaultNewLeaveDetail = {
    fromDate: moment(),
    toDate: moment(),
    morningEvening: ELeaveMorningEvening.full,
  }

  form: ILeave = {
    managerIds: [],
    details: [this.defaultNewLeaveDetail],
    status: ELeaveStatus.waiting,
    total: 0,
    note: '',
  }

  rules = {
    name: [this.requiredRule],
  }

  get modalTitle() {
    return this.isNew ? 'Add new leave' : 'Edit leave information'
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !this.id
  }

  get total() {
    return calculateTotalLeaveByDetail(this.form.details || [])
  }

  onAddNewUser(userIds: string[]) {
    this.form.managerIds = userIds
  }

  validateLeaveDetail(rule: { field: string }) {
    const index = Number(rule.field.split('.')[1])
    return new Promise((resolve, reject) => {
      /* eslint-disable */
      if (this.form.details?.length) {
        const valid1 = this.form.details[index]?.fromDate && this.form.details[index]?.toDate
        let valid2 = true
        if (valid1) {
          valid2 = this.form.details[index]?.fromDate <= this.form.details[index]?.toDate
        }

        if (!valid1 || !valid2) {
          return reject(new Error())
        }
      }

      return resolve(true)
    })
  }

  addLeaveDetail = () => {
    this.form.details?.push(this.defaultNewLeaveDetail)
  }

  removeLeaveDetail = (item: ILeaveDetail) => {
    const index = this.form.details?.indexOf(item)
    if (index === undefined) {
      return
    }

    if (index >= 0 && this.form.details) {
      this.form.details.splice(index, 1)
    }
  }

  fixDataToSave() {
    const dataToSave = cloneDeep(this.form)
    dataToSave.details?.map((detail) => {
      detail.fromDate = (detail.fromDate as moment.Moment).unix()
      detail.toDate = (detail.toDate as moment.Moment).unix()
    })

    // Store total as well
    dataToSave.total = this.total
    delete dataToSave.staffInfo

    return dataToSave
  }

  rawToFormData(rawData: ILeave) {
    rawData.details?.map((detail) => {
      detail.fromDate = moment.unix(detail.fromDate as number)
      detail.toDate = moment.unix(detail.toDate as number)
    })

    return rawData
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    this.loading = true
    const dataToSave = this.fixDataToSave()
    if (!this.id && this.userInfo.id) {
      dataToSave.userId = this.userInfo.id
      const doc = await FireLeave.add(dataToSave)
      FireLeave.sendMailToApprovers(doc.id)
    } else {
      await FireLeave.update(dataToSave)
    }

    message.success('Saved successfully')
    this.loading = false
    this.onCancel()
  }

  async getLeave() {
    return await FireLeave.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const leave = await this.getLeave()
      const dataForm = this.rawToFormData(leave)
      this.form = { ...this.form, ...dataForm }
    }
  }
}
