
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { ELeaveStatus, ILeave, IUser } from '@/utils/types'
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons-vue'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import LeaveStatusBadge from '@/components/leave/LeaveStatusBadge.vue'
import FireLeave from '@/services/leaves'
import { formatDate } from '@/utils/helpers'

@Options({
  components: { SearchUserInput, LeaveStatusBadge, CheckSquareOutlined, CloseOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class LeaveViewModal extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false
  leave: ILeave = {}

  formatDate = formatDate

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get canProcessAprove() {
    if (!this.userInfo.id) {
      return false
    }

    if (this.leave.status !== ELeaveStatus.waiting) {
      return false
    }

    return this.leave.managerIds?.includes(this.userInfo.id)
  }

  async getLeave() {
    return await FireLeave.get(this.id)
  }

  async onApprove() {
    this.loading = true
    await FireLeave.update({
      id: this.leave.id,
      status: ELeaveStatus.approved,
    })

    await FireLeave.sendMailApproved(this.id)

    message.success('Approved successfully')
    this.onCancel()
  }

  async onReject() {
    this.loading = true
    await FireLeave.update({
      id: this.leave.id,
      status: ELeaveStatus.rejected,
    })

    await FireLeave.sendMailRejected(this.id)

    message.success('Rejected successfully')
    this.onCancel()
  }

  @Emit('update:closeModal')
  onCancel() {
    this.loading = false
    return false
  }

  async created() {
    if (this.id) {
      this.leave = await this.getLeave()
    }
  }
}
