
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { ELeaveStatus, ILeave, IUser } from '@/utils/types'
import {
  UserSwitchOutlined,
  PlusOutlined,
  FormOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons-vue'
import FireLeave from '@/services/leaves'
import LeaveFormModal from '@/components/leave/LeaveFormModal.vue'
import LeaveViewModal from '@/components/leave/LeaveViewModal.vue'
import LeaveStatusBadge from '@/components/leave/LeaveStatusBadge.vue'
import DepartmentById from '@/components/department/DepartmentById.vue'
import { formatDate, formatDateTime } from '@/utils/helpers'

@Options({
  components: {
    LeaveFormModal,
    LeaveViewModal,
    DepartmentById,
    LeaveStatusBadge,
    UserSwitchOutlined,
    PlusOutlined,
    FormOutlined,
    DeleteOutlined,
    ClockCircleOutlined,
  },
  directives: { maska },
})
export default class LeaveList extends Vue {
  loading = false
  modalVisible = false
  modalLeaveDetailVisible = false
  selectedLeaveId = ''

  activeTab = 'my'
  tabKeys = {
    all: 'all',
    my: 'my',
    others: 'others',
  }

  searchQuery = ''

  leaves: ILeave[] = []
  leavesFiltered: ILeave[] = []

  columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      slots: { customRender: 'status' },
    },
    {
      title: 'Staff Name',
      dataIndex: 'staffInfo',
      key: 'staffName',
      slots: { customRender: 'staffName' },
    },
    {
      title: 'Departments',
      dataIndex: 'staffInfo',
      key: 'departmentIds',
      slots: { customRender: 'departmentIds' },
    },
    {
      title: 'Leave details',
      dataIndex: 'details',
      key: 'details',
      slots: { customRender: 'details' },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      slots: { customRender: 'total' },
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: ILeave, b: ILeave) => (a.createdAt || 0) - (b.createdAt || 0),
      slots: { customRender: 'createdAt' },
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a: ILeave, b: ILeave) => (a.updatedAt || 0) - (b.updatedAt || 0),
      slots: { customRender: 'updatedAt' },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      slots: { customRender: 'action' },
    },
  ]

  formatDate = formatDate
  formatDateTime = formatDateTime

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isOwner() {
    return this.$store.getters.isOwner
  }

  @Watch('leaves', { deep: true })
  leavesChanged() {
    this.leavesFiltered = reverse(uniqBy(reverse([...this.leaves]), 'id'))
  }

  @Watch('activeTab')
  activeTabChanged() {
    this.goto(this.$router.name, { tab: this.activeTab })
    this.getLeaves()
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  canModify(leave: ILeave) {
    return leave.status === ELeaveStatus.waiting
  }

  onAdd() {
    this.selectedLeaveId = ''
    this.modalVisible = true
  }

  onEdit(id: string) {
    this.selectedLeaveId = id
    this.modalVisible = true
  }

  onOpenLeaveDetail(record: ILeave) {
    if (!record.id) {
      return
    }

    this.modalLeaveDetailVisible = true
    this.selectedLeaveId = record.id
  }

  onCloseModal() {
    this.modalVisible = false
    this.modalLeaveDetailVisible = false
    this.selectedLeaveId = ''
  }

  async onDelete(id: string) {
    await FireLeave.delete(id)
    message.success('Deleted successfully')
  }

  async getMyLeaves() {
    if (!this.userInfo.id) {
      return
    }

    FireLeave.onMyLeaves(this.userInfo.id, async (staff: ILeave) => {
      if (staff.fireChangeType === 'removed') {
        this.leaves = this.leaves.filter((item) => item.id !== staff.id)
      } else {
        this.leaves.push(staff)
      }
    })
  }

  async getFromOtherLeaves() {
    if (!this.userInfo.id) {
      return
    }

    FireLeave.onFromOthers(this.userInfo.id, (staff: ILeave) => {
      if (staff.fireChangeType === 'removed') {
        this.leaves = this.leaves.filter((item) => item.id !== staff.id)
      } else {
        this.leaves.push(staff)
      }
    })
  }

  async getAllLeaves() {
    FireLeave.on((staff: ILeave) => {
      if (staff.fireChangeType === 'removed') {
        this.leaves = this.leaves.filter((item) => item.id !== staff.id)
      } else {
        this.leaves.push(staff)
      }
    })
  }

  async getLeaves() {
    this.leaves = []
    FireLeave.off()

    if (this.activeTab === this.tabKeys.my) {
      await this.getMyLeaves()
    } else if (this.activeTab === this.tabKeys.all) {
      await this.getAllLeaves()
    } else if (this.activeTab === this.tabKeys.others) {
      await this.getFromOtherLeaves()
    }
  }

  beforeDestroy() {
    FireLeave.off()
    this.leaves = []
  }

  created() {
    this.activeTab = this.$route.params?.tab || 'my'
    this.getLeaves()
  }
}
