
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class LeaveStatusBadge extends Vue {
  @Prop({ default: '' })
  status!: string
}
