<template>
  <a-modal class="ant-modal--xl" :visible="modalVisible" @cancel="onCancel" title="Leave confirm">
    <a-spin :spinning="!leave.id">
      <h2 class="text-sm">{{ leave.staffInfo?.firstName }} {{ leave.staffInfo?.lastName }}</h2>
      <a-list bordered>
        <a-list-item v-for="(detail, index) in leave.details" :key="index">
          <a-tag>
            <span class="text-sm"> {{ formatDate(detail.fromDate) }} ~ {{ formatDate(detail.toDate) }} </span>
          </a-tag>

          <span class="text-white font-bold">
            <a-tag color="green">{{ detail.morningEvening }} </a-tag>
          </span>
        </a-list-item>

        <a-list-item>
          <span>Total request (days)</span>

          <span class="text-white font-bold">
            <a-tag color="green">{{ leave.total }} </a-tag>
          </span>
        </a-list-item>

        <a-list-item>
          <span>Status</span>

          <span>
            <LeaveStatusBadge :status="leave.status" />
          </span>
        </a-list-item>

        <a-list-item>
          <div>
            <h3>Note</h3>
            <div class="text-gray-400">
              {{ leave.note }}
            </div>
          </div>
        </a-list-item>
      </a-list>

      <a-space class="mt-4" v-if="canProcessAprove">
        <a-popconfirm title="Are you sure？" ok-text="Yes" cancel-text="No" @confirm="onApprove">
          <a-button type="primary" size="large">
            <template #icon><CheckSquareOutlined /></template>
            Approve
          </a-button>
        </a-popconfirm>
        <a-popconfirm title="Are you sure？" ok-text="Yes" cancel-text="No" @confirm="onReject">
          <a-button danger size="large">
            <template #icon><CloseOutlined /></template>
            Reject
          </a-button>
        </a-popconfirm>
      </a-space>

      <template #footer>
        <a-button key="back" type="default" @click="onCancel">OK</a-button>
      </template>
    </a-spin>
  </a-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { ELeaveStatus, ILeave, IUser } from '@/utils/types'
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons-vue'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import LeaveStatusBadge from '@/components/leave/LeaveStatusBadge.vue'
import FireLeave from '@/services/leaves'
import { formatDate } from '@/utils/helpers'

@Options({
  components: { SearchUserInput, LeaveStatusBadge, CheckSquareOutlined, CloseOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class LeaveViewModal extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false
  leave: ILeave = {}

  formatDate = formatDate

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get canProcessAprove() {
    if (!this.userInfo.id) {
      return false
    }

    if (this.leave.status !== ELeaveStatus.waiting) {
      return false
    }

    return this.leave.managerIds?.includes(this.userInfo.id)
  }

  async getLeave() {
    return await FireLeave.get(this.id)
  }

  async onApprove() {
    this.loading = true
    await FireLeave.update({
      id: this.leave.id,
      status: ELeaveStatus.approved,
    })

    await FireLeave.sendMailApproved(this.id)

    message.success('Approved successfully')
    this.onCancel()
  }

  async onReject() {
    this.loading = true
    await FireLeave.update({
      id: this.leave.id,
      status: ELeaveStatus.rejected,
    })

    await FireLeave.sendMailRejected(this.id)

    message.success('Rejected successfully')
    this.onCancel()
  }

  @Emit('update:closeModal')
  onCancel() {
    this.loading = false
    return false
  }

  async created() {
    if (this.id) {
      this.leave = await this.getLeave()
    }
  }
}
</script>
